<template>
  <v-app>
    <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <v-container class="container">
      <v-card class="grid" :elevation="11" width="95%">
        <v-card-title class="text-center justify-center title">
          TOTAL SALES TILL DATE {{ currentDate }} IS RM {{ totalSales }}
        </v-card-title>
        <!-- <v-responsive :aspect-ratio="4/3"> -->
        <v-tabs bg-color="#FFFFFFC9" color="#80ff00 " grow>
          <v-tab @click="loadTab('Users')"> Users </v-tab>
          <v-tab @click="loadTab('Cars')"> Cars </v-tab>
          <v-tab @click="loadTab('Bookings')"> Bookings </v-tab>
          <v-tab @click="loadTab('Expenses')"> Expenses </v-tab>
          <v-tab @click="loadTab('Trackers')"> Trackers </v-tab>
        </v-tabs>
        <!-- </v-responsive> -->
        <div v-if="showUsers">
          <v-card class="grid" color="transparent">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field
          ></v-card>
          <v-data-table
            :headers="userHeaders"
            :items="users"
            :search="search"
            :footer-props="{
              itemsPerPageOptions: [30, 60, 90, 120, 150],
              'items-per-page-text': 'Users per page:',
              'show-current-page': true,
              'show-first-last-page': true,
            }"
          >
            <template v-slot:item.isActive="{ item }">
              <v-simple-checkbox
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                v-model="item.isActive"
                :disabled="item.isActive"
                @click="activateUser(item._id)"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.edit="{ item }">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                small
                class="mr-2"
                @click="editUser(item._id)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </div>
        <div v-if="showCars">
          <v-card class="grid" color="transparent">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            >
            </v-text-field
          ></v-card>
          <v-row class="justify-center align-center" no-gutters
            ><v-btn
              class="btnWa"
              style="margin-top: 2% !important; margin-bottom: 2% !important"
              @click="addCar"
            >
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-card-plus-outline mdi-18px
              </v-icon>
              Add Car
            </v-btn></v-row
          >
          <v-data-table
            :headers="carHeaders"
            :items="cars"
            :search="search"
            :footer-props="{
              itemsPerPageOptions: [30, 60, 90, 120, 150],
              'items-per-page-text': 'Cars per page:',
              'show-current-page': true,
              'show-first-last-page': true,
            }"
          >
            <template v-slot:item.edit="{ item }">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                small
                class="mr-2"
                @click="select(item._id)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </div>
        <div v-if="showBookings">
          <v-card class="grid" color="transparent">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-row align="center">
              <v-col :cols="4" align="center"
                ><v-btn class="btnWa" @click="addBooking">
                  <v-icon
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  >
                    mdi-card-plus-outline mdi-18px
                  </v-icon>
                  Add Booking
                </v-btn></v-col
              >
              <v-col :cols="4" align="center">
                <v-btn class="btnWa" @click="advancedFilter">
                  <v-icon
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  >
                    mdi-card-plus-outline mdi-18px
                  </v-icon>
                  Advanced Filter
                </v-btn></v-col
              ></v-row
            ></v-card
          >
          <v-data-table
            :headers="bookingHeaders"
            :items="bookings"
            :search="search"
            :footer-props="{
              itemsPerPageOptions: [30, 60, 90, 120, 150],
              'items-per-page-text': 'Bookings per page:',
              'show-current-page': true,
              'show-first-last-page': true,
            }"
          >
            <template #item.reservationDateStart="{ item }">
              {{ formatDate(item.reservationDateStart) }}
            </template>
            <template #item.reservationDateEnd="{ item }">
              {{ formatDate(item.reservationDateEnd) }}
            </template>
            <template v-slot:item.edit="{ item }">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                small
                class="mr-2"
                @click="editBooking(item._id)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </div>
        <div v-if="showExpenses">
          <v-card-text class="text">
            <label for="filterDate">Filter by Date:</label></v-card-text
          >
          <v-card-text class="text"
            ><input type="date" v-model="filterDate" id="filterDate"
          /></v-card-text>
          <v-card-text class="text"
            >Total expense on selected date: {{ totalExpense }}</v-card-text
          >
          <v-card-text class="text">
            <ul>
              <li v-for="expense in filteredExpenses" :key="expense.id">
                {{ formatDate(expense.startDate) }} - {{ expense.totalPrice }} -
                {{ expense.name }} -
                {{ expense.creator }}
              </li>
            </ul>
          </v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-text class="d-flex align-center justify-center about"
                  >Expenses</v-card-text
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card class="grid" color="transparent">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card>
                <v-data-table
                  :headers="expensesHeaders"
                  :items="expenses"
                  :search="search"
                  :footer-props="{
                    itemsPerPageOptions: [30, 60, 90, 120, 150],
                    'items-per-page-text': 'Expenses per page:',
                    'show-current-page': true,
                    'show-first-last-page': true,
                  }"
                >
                  <template #item.startDate="{ item }">
                    {{ formatDate(item.startDate) }}
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <v-icon small class="mr-2" @click="editExpenses(item._id)">
                      mdi-pencil
                    </v-icon>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row align="center" justify="center">
            <v-btn class="btnWa" @click="addExpenses = true" style="margin-top: 2% !important; margin-bottom: 2% !important;">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-account-plus-outline mdi-18px
              </v-icon>
              Add Expense
            </v-btn>
          </v-row>
          <div v-if="addExpenses">
            <v-card shaped class="grid">
              <v-card-text class="d-flex align-center justify-center about"
                >Expense Logger</v-card-text
              >
              <form @submit.prevent="addExpense">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-card-text class="text">Car Details</v-card-text>
                    <v-select
                      dark
                      v-model="expense.carId"
                      :rules="[rules.required]"
                      :items="cars"
                      item-text="plateNumber"
                      item-value="_id"
                      required
                      class="text"
                      dense
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-card-text class="text">Expense Date</v-card-text>
                    <v-text-field
                      dark
                      v-model="expense.startDate"
                      solo
                      required
                      :rules="[rules.required]"
                      outlined
                      dense
                    ></v-text-field>
                    <v-date-picker
                      v-model="expense.startDate"
                      @input="setStartDate"
                      dense
                      solo
                      width="90%"
                      height="20%"
                      dark
                    ></v-date-picker>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card-text class="text">Price Paid (RM)</v-card-text>
                    <v-text-field
                      dark
                      v-model="expense.totalPrice"
                      solo
                      required
                      :rules="[rules.required]"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-card-text class="text"
                      >Expense Details (eg : Petrol)</v-card-text
                    >
                    <v-text-field
                      dark
                      v-model="expense.name"
                      solo
                      :rules="[rules.required]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center align-center" no-gutters>
                  <v-btn class="btnWa" type="submit">
                    <v-icon style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        ">
                      mdi-account-plus-outline mdi-18px
                    </v-icon>
                    Submit
                  </v-btn>
                </v-row>
              </form>
            </v-card>
          </div>
        </div>
        <div v-if="showTrackers">
          <v-card-text class="text">
            <label for="filterDate">Filter by Date:</label></v-card-text
          >
          <v-card-text class="text"
            ><input type="date" v-model="filterDate" id="filterDate"
          /></v-card-text>
          <v-card-text class="text">
            <ul>
              <li v-for="tracker in filteredTrackers" :key="tracker._id">
                {{ tracker.data }} - {{ tracker.count }}
              </li>
            </ul>
          </v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-text class="d-flex align-center justify-center about"
                  >Trackers</v-card-text
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card class="grid" color="transparent">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card>
                <v-data-table
                  :headers="trackerHeaders"
                  :items="trackers"
                  :search="search"
                  :footer-props="{
                    itemsPerPageOptions: [30, 60, 90, 120, 150],
                    'items-per-page-text': 'Trackers per page:',
                    'show-current-page': true,
                    'show-first-last-page': true,
                  }"
                >
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();
export default {
  data() {
    return {
      name: this.$store.state.userProfile.name,
      // tab: 'web',
      showUsers: true,
      users: [],
      currentDate: null,
      userHeaders: [
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email", sortable: false, align: "center" },
        {
          text: "Contact Number",
          value: "mobileNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "Identification / Passport Number",
          value: "primaryIdentificationNum",
          sortable: false,
          align: "center",
        },
        {
          text: "Active User?",
          value: "isActive",
          align: "center",
          filterable: false,
        },
        {
          text: "Edit",
          value: "edit",
          align: "center",
          filterable: false,
          sortable: false,
        },
      ],
      search: "",
      showCars: false,
      cars: [],
      carHeaders: [
        {
          text: "Number Plate",
          align: "center",
          sortable: false,
          value: "plateNumber",
        },
        { text: "Status", align: "center", value: "rentStatus" },
        {
          text: "Make",
          align: "center",
          value: "make",
        },
        {
          text: "Model",
          sortable: false,
          align: "center",
          value: "model",
        },
        {
          text: "Price Per Day",
          align: "center",
          filterable: false,
          value: "pricePerDay",
        },
        {
          text: "Price 3 Days",
          align: "center",
          filterable: false,
          value: "pricePer3days",
        },
        {
          text: "Price Per Week",
          align: "center",
          filterable: false,
          value: "pricePerWeek",
        },
        {
          text: "Edit",
          value: "edit",
          align: "center",
          filterable: false,
          sortable: false,
        },
      ],
      extended: false,
      showBookings: false,
      bookings: [],
      bookingHeaders: [
        {
          text: "Number Plate",
          align: "center",
          sortable: false,
          value: "plateNumber",
        },
        {
          text: "Start Date",
          align: "center",
          value: "reservationDateStart",
        },
        {
          text: "End Date",
          sortable: false,
          align: "center",
          value: "reservationDateEnd",
        },
        {
          text: "Net Price (RM)",
          align: "center",
          filterable: false,
          value: "totalPrice",
        },
        {
          text: "Agent Name",
          align: "center",
          filterable: false,
          value: "agentName",
        },
        {
          text: "Agent Commisions",
          align: "center",
          filterable: false,
          value: "agentCommisions",
        },
        {
          text: "Extended Booking?",
          align: "center",
          filterable: false,
          value: "extensionEndDate",
        },
        {
          text: "Edit",
          value: "edit",
          align: "center",
          filterable: false,
          sortable: false,
        },
      ],
      expensesHeaders: [
        {
          text: "Number Plate",
          align: "center",
          sortable: false,
          value: "plateNumber",
        },
        {
          text: "Start Date",
          align: "center",
          value: "startDate",
        },
        {
          text: "Expense Amount",
          sortable: false,
          align: "center",
          value: "totalPrice",
        },
        {
          text: "Expense Name",
          align: "center",
          filterable: false,
          value: "name",
        },
        {
          text: "Admin Email",
          align: "center",
          filterable: false,
          value: "creator",
        },
      ],
      expenses: [],
      totalSales: 0,
      showExpenses: false,
      expense: {
        startDate: null,
      },
      filterDate: "",
      startDate: null,
      addExpenses: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      showTrackers: false,
      trackerHeaders: [
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Button Name",
          align: "center",
          value: "buttonName",
        },
        {
          text: "Data Type",
          sortable: false,
          align: "center",
          value: "dataType",
        },
        {
          text: "Mobile (IF USER)",
          align: "center",
          value: "mobileNumber",
        },
        {
          text: "Clicked at",
          align: "center",
          value: "created_at",
        },
      ],
      trackers: [],
    };
  },
  mounted: function () {
    this.getUsers();
    this.currentDate = new Date().toISOString().substr(0, 10);
  },
  computed: {
    filteredExpenses() {
      if (this.filterDate) {
        return this.expenses.filter((expense) => {
          return expense.startDate.substr(0, 10) === this.filterDate;
        });
      } else {
        return [];
      }
    },
    totalExpense() {
      return this.filteredExpenses.reduce((total, expense) => {
        return total + Number(expense.totalPrice);
      }, 0);
    },
    filteredTrackers() {
      // Filter trackers based on the specified date
      const filtered = this.trackers.filter((tracker) => {
        return tracker.created_at === this.filterDate;
      });

      // Count the occurrences of each dataType
      const countMap = {};
      filtered.forEach((tracker) => {
        const dataType = tracker.dataType;
        countMap[dataType] = countMap[dataType] ? countMap[dataType] + 1 : 1;
      });

      // Sort the filtered trackers by the count of dataType
      const sorted = filtered.sort((a, b) => {
        const countA = countMap[a.dataType];
        const countB = countMap[b.dataType];

        // Sort by count first
        if (countB !== countA) {
          return countB - countA; // Sort in descending order of count
        }

        // If counts are the same, sort by dataType alphabetically
        return a.dataType.localeCompare(b.dataType);
      });

      // Create an array of objects with tracker and count
      const sortedWithCount = sorted.map((tracker) => {
        return {
          data: tracker.dataType,
          count: countMap[tracker.dataType],
        };
      });

      const array = sortedWithCount;
      const uniqueArray = array.filter((obj, index, self) => {
        return index === self.findIndex((o) => o.data === obj.data);
      });

      return uniqueArray;
    },
  },

  methods: {
    loadTab(a) {
      if (a == "Users") {
        this.showUsers = true;
        this.showCars = false;
        this.showBookings = false;
        this.showExpenses = false;
        this.showTrackers = false;
      } else if (a == "Cars") {
        this.showUsers = false;
        this.showCars = true;
        this.showBookings = false;
        this.showExpenses = false;
        this.showTrackers = false;
      } else if (a == "Bookings") {
        this.showUsers = false;
        this.showCars = false;
        this.showBookings = true;
        this.showExpenses = false;
        this.showTrackers = false;
      } else if (a == "Expenses") {
        this.showUsers = false;
        this.showCars = false;
        this.showBookings = false;
        this.showExpenses = true;
        this.showTrackers = false;
      } else if (a == "Trackers") {
        this.showUsers = false;
        this.showCars = false;
        this.showBookings = false;
        this.showExpenses = false;
        this.showTrackers = true;
      }
    },
    async getUsers() {
      if (this.$store.state.user.roles[0] == "admin") {
        this.$setLoader();
        await dataService.fetchUsers().then((res) => {
          this.users = res.data.users;
        });
        this.getCars();
        this.getBookings();
        this.getExpenses();
        this.getTrackers();
        this.$disableLoader();
      } else {
        alert("USER NOT AUTHORIZED TO VIEW THIS PAGE");
        this.$router.push({ path: `/car-rental-seremban` });
      }
    },

    async getCars() {
      this.$setLoader();
      await dataService.getAllCars().then((res) => {
        this.cars = res.data.cars;
      });

      this.$disableLoader();
    },

    async getBookings() {
      this.$setLoader();
      await dataService.getBookings().then((res) => {
        this.bookings = res.data.getBookings;
        for (let booking of this.bookings) {
          if (!booking.extentensionPrice) {
            booking.extentensionPrice = 0;
          }
          this.totalSales +=
            Number(booking.totalPrice) + Number(booking.extentensionPrice);
          if (
            booking.extensionEndDate != undefined ||
            booking.extensionEndDate != null
          ) {
            booking.extensionEndDate = true;
          } else {
            booking.extensionEndDate = false;
          }
        }
      });

      this.$disableLoader();
    },

    async getExpenses() {
      this.$setLoader();
      await dataService.getExpenses().then((res) => {
        this.expenses = res.data.expenses;
      });

      this.$disableLoader();
    },

    async getTrackers() {
      this.$setLoader();
      await dataService.getTrackers().then((res) => {
        this.trackers = res.data.trackers;
      });

      this.$disableLoader();
    },

    async addExpense() {
      this.$setLoader();
      for (let car of this.cars) {
        if (this.expense.carId == car._id) {
          this.expense.plateNumber = car.plateNumber;
        }
      }
      this.expense.creator = this.$store.state.userProfile.email;
      await dataService.addExpenses(this.expense).then((res) => {
        console.log(res);
        window.location.reload();
      });

      this.$disableLoader();
    },

    formatDate(dateString) {
      // const date = new Date(dateString);
      // const options = { timeZone: 'UTC' };
      // return date.toLocaleString('en-US', options);
      if (dateString != null) {
        const newDate = dateString;
        const date = newDate.slice(0, 10);
        return date;
      } else {
        return null;
      }
    },

    async activateUser(id) {
      this.$setLoader();
      await dataService.activateUser({ _id: id }).then(() => {
        this.getUsers();
      });

      this.$disableLoader();
    },

    addCar() {
      this.$setLoader();
      this.$router.push({ path: `/addCar` });
      this.$disableLoader();
    },

    addBooking() {
      this.$setLoader();
      this.$router.push({ path: `/addBooking` });
      this.$disableLoader();
    },

    advancedFilter() {
      this.$setLoader();
      this.$router.push({ path: `/bookings` });
      this.$disableLoader();
    },

    select(cardId) {
      this.$setLoader();
      this.$router.push({ path: `/car/${cardId}` });
      this.$disableLoader();
    },

    editUser(cardId) {
      this.$setLoader();
      this.$router.push({ path: `/editUser/${cardId}` });
      this.$disableLoader();
    },

    editBooking(cardId) {
      this.$setLoader();
      this.$router.push({ path: `/editBooking/${cardId}` });
      this.$disableLoader();
    },

    setStartDate(newValue) {
      this.startDate = newValue;
      this.expense.startDate = newValue;
    },
  },
};
</script>

<style scoped lang="css">
.container {
  /* margin-top: 5%; */
  max-width: none !important;
}
#particles-js {
  background-color: #f9ece0 !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0 !important;
}
</style>
